<div class="layout-topbar">

    <button #menubutton class="p-link layout-menu-button layout-topbar-button m-3"
        (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <a class="layout-topbar-logo">
        <span (click)="home()">MAHARAJA SOAPS </span>
    </a>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <button class="p-link layout-topbar-button" (click)="signOut()" pTooltip="Sign Out" tooltipPosition="bottom">
            <i class="pi pi-sign-out"></i>
            <span>Sign out</span>

        </button>

    </div>

</div>