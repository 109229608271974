import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AdminAuthService } from './admin-auth.service';

@Injectable({
    providedIn: 'root',
})
export class AdminAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: AuthService,
        private adminAuth: AdminAuthService,
        private route: Router
    ) {}
    canActivate() {
        if (this.adminAuth.IsLoggedIn()) {
            return true;
        } else {
            this.route.navigate(['/auth/admin-login']);
            return false;
        }
    }
    canActivateChild() {
        return true;
    }
}
