import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import packageJson from 'package.json';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styles: [
        `
            .layout-footer {
                justify-content: center;
                align-items: center;
                padding-bottom: 10px;
                background: #fff;
            }
        `,
    ],
})
export class AppFooterComponent {
    public version: string = packageJson.version;

    constructor(public layoutService: LayoutService) {}
}
