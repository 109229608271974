import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { mainURL } from './constant';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient) {}

    private isLoggedIn = false;

    status: any;
    s_name: string;
    s_id: number;
    access_token: any;

    login(data: any) {
        let body = {
            username: data.username,
            password: data.password,
        };

        return this.http.post<any>(`${mainURL}/sales-app/login`, body);
    }

    storeLoginDets(logInfo?: any): any {
        this.status = logInfo.status;
        this.s_name = logInfo.s_username;
        this.s_id = logInfo.s_uid;
        this.access_token = logInfo.access_token;
    }

    IsLoggedIn() {
        return localStorage.getItem('status') != null;
    }

    getToken() {
        return localStorage.getItem('access_token') || '';
    }

    clearToken() {
        return localStorage.clear();
    }
    getIsLoggedIn(): boolean {
        return this.isLoggedIn;
    }

    apiPostCall(url: string, data: any) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            "ngrok-skip-browser-warning": "69420",
        };
        return this.http.post<any>(url, data, {
            headers: headers,
        });
    }

    apiGetCall(url: string) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            "ngrok-skip-browser-warning": "69420",
        };
        return this.http.get<any>(url, { headers: headers });
    }
}
