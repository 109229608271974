import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import packageJson from 'package.json';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styles: [
        `
            .layout-footer {
                justify-content: center;
                align-items: center;
                padding-bottom: 10px;
            }
            .copy-icon {
                cursor: pointer;
                margin-left: 10px;
            }
            .copied-message {
                color: green;
                font-size: 1rem;
                margin-left: 10px;
            }
        `,
    ],
})
export class AppFooterComponent implements OnInit {
    public version: string = packageJson.version;
    public displayDialog: boolean = false;
    public localStorageData: any;
    public indexedDBData: any;
    public localStorageCopiedMessage: string = '';
    public indexedDBCopiedMessage: string = '';

    constructor(
        public layoutService: LayoutService,
        private clipboard: Clipboard
    ) {}

    ngOnInit(): void {}

    openDialog() {
        this.displayDialog = true;
        this.fetchLocalStorageData();
        this.fetchIndexedDBData();
    }

    fetchLocalStorageData() {
        this.localStorageData = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key) {
                this.localStorageData[key] = localStorage.getItem(key);
            }
        }
    }

    fetchIndexedDBData() {
        const request = indexedDB.open('myDatabase', 1);
        request.onsuccess = (event: any) => {
            const db = event.target.result;
            const transaction = db.transaction(['offline-checkins']);
            const store = transaction.objectStore('offline-checkins');
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = (event: any) => {
                const rawData = event.target.result;

                if (rawData && rawData.length > 0) {
                    this.indexedDBData = rawData;
                } else {
                    this.indexedDBData = 'No data found';
                }
            };
        };

        request.onerror = (event: any) => {
            this.indexedDBData = 'No data found';
            console.error('Error fetching data from IndexedDB', event);
        };
    }

    copyToClipboard(data: any, type: 'localStorage' | 'indexedDB') {
        const formattedData = JSON.stringify(data, null, 2);
        this.clipboard.copy(formattedData);
        if (type === 'localStorage') {
            this.localStorageCopiedMessage = 'Copied!';
            setTimeout(() => {
                this.localStorageCopiedMessage = '';
            }, 2000);
        } else if (type === 'indexedDB') {
            this.indexedDBCopiedMessage = 'Copied!';
            setTimeout(() => {
                this.indexedDBCopiedMessage = '';
            }, 2000);
        }
    }
}
