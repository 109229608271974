import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { NavigationExtras, Router } from '@angular/router';
import { InventoryService } from '../demo/service/inventory.service';
import { AdminAuthService } from '../demo/service/admin-auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [
        `
            .layout-topbar-logo {
                flex: 1 1 auto;
            }
            .back {
                border: 0px;
                flex: 0 1 auto;
            }
        `,
    ],
})
export class AppTopBarComponent {
    date: Date | undefined;
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private route: Router,
        private inventory: InventoryService,
        private adminService: AdminAuthService
    ) {}

    home() {
        this.route.navigate(['admin-panel/pages/orders']);
        window.history.pushState(null, '', '/admin-panel/pages/orders');
    }

    signOut() {
        this.route.navigate(['/auth/admin']);
    }
}
