import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AdminAuthService } from './admin-auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: AuthService,
        private route: Router,
        private adminAuthService: AdminAuthService
    ) {}
    canActivate() {
        if (this.authService.IsLoggedIn()) {
            return true;
        } else if (this.adminAuthService.IsAdmin()) {
            this.route.navigate(['/admin-panel/pages/orders']);
            return false;
        } else {
            this.route.navigate(['/auth/login']);
            return false;
        }
    }
    canActivateChild() {
        return true;
    }
}
