<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/maharaja-soaps-v1.png" alt="Maharaja Soaps" class="mb-5 w-6rem flex-shrink-0">
        <div
            style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(33, 150, 243, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center"
                style="border-radius:53px">
                <span class="text-blue-500 font-bold text-3xl mb-3">Comming Soon!!</span>

                <div class="text-600 mb-5">Requested page is under construction.</div>
                <a [routerLink]="['/']" class="w-full flex align-items-center py-5 border-300 ">
                    <span class="flex justify-content-center align-items-center bg-cyan-400 border-round"
                        style="height:3.5rem; width:3.5rem;">
                        <i class="text-50 pi pi-fw pi-home text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0 block">Go back to home</span>

                    </span>
                </a>

            </div>
        </div>
    </div>
</div>