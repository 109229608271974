import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { NavigationExtras, Router } from '@angular/router';
import { InventoryService } from '../demo/service/inventory.service';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [
        `
            .layout-topbar-logo {
                flex: 1 1 auto;
            }
            .back {
                border: 0px;
                flex: 0 1 auto;
            }
        `,
    ],
})
export class AppTopBarComponent {
    date: Date | undefined;
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private route: Router,
        private inventoryService: InventoryService,
        private appComponent: AppComponent
    ) {}

    home() {
        this.route.navigate(['/']);
        window.history.pushState(null, '', '/');
        this.inventoryService.specialComments = '';
        this.inventoryService.ouid = '';
    }

    signOut() {
        this.route.navigate(['/auth/login']);
    }

    forceUpdate() {
        this.appComponent.forceUpdate();
    }
}
