import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    NgModule,
    isDevMode,
} from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommingSoonComponent } from './demo/components/commingsoon/comming-soon.component';
import { AdminAppLayoutModule } from './admin-layout/app.layout.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
    declarations: [AppComponent, NotfoundComponent, CommingSoonComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        AdminAppLayoutModule,
        FormsModule,
        ServiceWorkerModule.register('service-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
