import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as SentryBrowser from '@sentry/browser';
import { environment } from './environments/environment';

if (environment.production) {
    SentryBrowser.init({
        dsn: 'https://f22ec7ea031a7763a5a53819b67916a9@o4506709239660544.ingest.us.sentry.io/4507701548482560',
        integrations: [
            SentryBrowser.browserTracingIntegration(),
            SentryBrowser.browserProfilingIntegration(),
            SentryBrowser.captureConsoleIntegration(),
            SentryBrowser.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
            }),
            SentryBrowser.makeBrowserOfflineTransport(
                SentryBrowser.makeFetchTransport
            ),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/ms-admin\.skylinebiz\.in$/,
            /^https:\/\/maharajasoaps-d5a20\.web\.app$/,
            /^https:\/\/ms-api\.skylinebiz\.in\/api$/,
            /^https:\/\/7a1d-49-36-234-8\.ngrok-free\.app$/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        profilesSampleRate: 1.0,
    });

    window.addEventListener('online', () => {
        SentryBrowser.flush(500);
    });
}

enableProdMode();
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
