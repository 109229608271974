<div class="layout-topbar">
    <a class="layout-topbar-logo">
        <img src="assets/maharaja-soaps-v1.png" alt="logo" (click)="home()">
        <span (click)="home()">Maharaja Soaps</span>
    </a>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <button class="p-link layout-topbar-button" (click)="forceUpdate()" pTooltip="Force Update"
            tooltipPosition="bottom">
            <i class="pi pi-sync"></i>
            <span>Force Update</span>
        </button>

        <button class="p-link layout-topbar-button" (click)="signOut()" pTooltip="Sign Out" tooltipPosition="bottom">
            <i class="pi pi-sign-out"></i>
            <span>Sign out</span>

        </button>
    </div>
</div>