import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { mainURL } from './constant';

@Injectable({
    providedIn: 'root',
})
export class InventoryService {
    sUid: any;
    dUid: any;
    puid: any;
    ouid: any;
    uName: any;
    products: any[] = [];
    inventoryList: any;
    orderList: any;
    order: any[];
    cartContent: any;
    edit: any[];
    specialComments: any;

    constructor(private http: HttpClient, private authService: AuthService) {}

    headers = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };

    saveDUid(d: any) {
        this.dUid = d;
    }

    saveSid(s: any) {
        let s_uid = localStorage.getItem('sUid');
        let sUname = localStorage.getItem('sUserName');
        this.sUid = s;
    }

    hasCachedProducts(): boolean {
        return this.products.length > 0;
    }

    getCachedProducts(): any[] {
        return this.products;
    }

    getProducts(d: any): Observable<any[]> {
        if (this.dUid !== d) {
            this.products = [];
            this.inventoryList = null;
        }
        if (this.products.length > 0) {
            return of(this.products);
        } else {

            return this.authService
                .apiGetCall(`${mainURL}/sales-app/products-list?d_uid=${d}`)
                .pipe(tap((products: any) => (this.products = products)));
        }
    }

    addToCart(addItem: any) {
        this.order = addItem;
    }

    maintainCart(maintain: any) {
        this.cartContent = maintain;
    }
}
