<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="layout-wrapper user" [ngClass]="containerClass">
    <app-topbar></app-topbar>

    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>

    </div>
    <app-footer></app-footer>
    <div class="layout-mask"></div>
</div>

