import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
    SwUpdate,
    UnrecoverableStateEvent,
    VersionEvent,
} from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [``],
})
export class AppComponent implements OnInit {
    latitude: any;
    longitude: any;
    constructor(
        private primengConfig: PrimeNGConfig,
        private updates: SwUpdate
    ) {}

    async ngOnInit() {
        this.primengConfig.ripple = true;

        if (!this.updates.isEnabled) {
            return;
        }

        this.handleUpdates();
    }

    handleUpdates() {
        let currentVersionHash = '';
        let latestVersionHash = '';

        this.updates.versionUpdates.subscribe((evt: VersionEvent) => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    latestVersionHash = evt.version.hash;
                    alert(
                        `An update is available. Please click OK to apply the update.`
                    );
                    // Perform update installation here
                    break;
                case 'VERSION_READY':
                    currentVersionHash = evt.currentVersion.hash;
                    latestVersionHash = evt.latestVersion.hash;

                    if (currentVersionHash !== latestVersionHash) {
                        alert(
                            'The update has been installed. The app will now reload.'
                        );
                        location.reload();
                    }
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    alert(
                        `Failed to install app version '${evt.version.hash}': ${evt.error}`
                    );
                    break;
                case 'NO_NEW_VERSION_DETECTED':
                    // console.log(`No update detected.`);
                    break;
            }
        });

        this.updates.unrecoverable.subscribe((evt: UnrecoverableStateEvent) => {
            alert('Error reason: ' + evt.reason);
        });

        setInterval(() => {
            this.handleUpdates();
        }, 6 * 60 * 60 * 1000);
    }

    forceCheckUpdate() {
        this.updates
            .checkForUpdate()
            .then((updateFound) => {
                if (updateFound) {
                    console.log('Update available.');
                } else {
                    console.log('No update found.');
                    alert('You have the latest version.');
                }
            })
            .catch((err) => {
                console.error('Error checking for updates:', err);
            });
    }

    forceUpdate() {
        this.forceCheckUpdate();
    }
}
