import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mainURL } from './constant';


@Injectable({
    providedIn: 'root',
})
export class AdminAuthService {
    constructor(private http: HttpClient) {}

    status: any;
    admin_name: string;
    admin_id: number;
    token: any;

    adminLogin(data?: any) {
        let body = {
            email: data.email,
            password: data.password,
        };
        return this.http.post<any>(`${mainURL}/login`, body);
    }

    storeAdminLoginDets(data?: any): any {
        this.admin_id = data.user.id;
        this.token = data.access_token;
    }

    IsAdmin() {
        return localStorage.getItem('role') == 'admin';
    }
    IsLoggedIn() {
        return localStorage.getItem('token') != null;
    }
    getToken() {
        return localStorage.getItem('token') || '';
    }

    apiPostCall(url: string, data: any) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "ngrok-skip-browser-warning": "69420",
        };
        
        return this.http.post<any>(url, data, {
            headers: headers,
        });
    }

    apiGetCall(url: string) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "ngrok-skip-browser-warning": "69420",
        };

        return this.http.get<any>(url, { headers: headers });
    }

    apiPutCall(url: string, data: any) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "ngrok-skip-browser-warning": "69420",
        };

        return this.http.put<any>(url, data, {
            headers: headers,
        });
    }

    apiDeleteCall(url: string) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'ngrok-skip-browser-warning': '69420',
        };

        return this.http.delete<any>(url, { headers: headers });
    }
}
