import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './demo/service/auth.guard';
import { CommingSoonComponent } from './demo/components/commingsoon/comming-soon.component';

import { AdminAppLayoutComponent } from './admin-layout/app.layout.component';
import { AdminAuthGuard } from './demo/service/admin-auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: AppLayoutComponent,
                    children: [
                        {
                            path: '',
                            loadChildren: () =>
                                import(
                                    './demo/components/dashboard/dashboard.module'
                                ).then((m) => m.DashboardModule),
                        },
                        {
                            path: 'uikit',
                            loadChildren: () =>
                                import(
                                    './demo/components/uikit/uikit.module'
                                ).then((m) => m.UIkitModule),
                        },
                    ],
                    canActivate: [AuthGuard],
                },
                {
                    path: 'auth',
                    loadChildren: () =>
                        import('./demo/components/auth/auth.module').then(
                            (m) => m.AuthModule
                        ),
                },
                {
                    path: 'notfound',
                    component: NotfoundComponent,
                    canActivate: [AuthGuard],
                },

                {
                    path: 'admin-panel',
                    component: AdminAppLayoutComponent,
                    children: [
                        {
                            path: 'pages',
                            loadChildren: () =>
                                import(
                                    './demo/components/pages/pages.module'
                                ).then((m) => m.PagesModule),
                        },
                        {
                            path: 'admin-reports',
                            loadChildren: () =>
                                import(
                                    './demo/components/admin-reports/admin-reports.module'
                                ).then((m) => m.AdminReportsModule),
                        },
                    ],
                    canActivate: [AdminAuthGuard],
                },

                {
                    path: 'commingSoon',
                    component: CommingSoonComponent,
                    canActivate: [AuthGuard],
                },

                { path: '**', redirectTo: '/notfound' },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
