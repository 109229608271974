<div class="layout-footer">
    <span class="font-medium ml-2">SkylineBiz Private Limited <span
            (dblclick)="openDialog()">(v{{version}})</span></span>


    <p-dialog [(visible)]="displayDialog" [modal]="true" [closable]="true"
        [style]="{ 'width': '80vw', 'padding': '2px', 'margin': '2px' }">


        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Data from Local Storage and IndexedDB</span>
        </ng-template>

        <ng-template pTemplate="content">
            <div>
                <div>
                    <span class="text-xl text-center font-bold text-justify">
                        Local Storage Data
                    </span>
                    <i class="pi pi-copy copy-icon" style="font-size: 1.25rem"
                        (click)="copyToClipboard(localStorageData, 'localStorage')"></i>
                    <span *ngIf="localStorageCopiedMessage" class="copied-message">{{ localStorageCopiedMessage
                        }}</span>
                </div>
                <pre>{{ localStorageData |json  }}</pre>
            </div>
            <div>
                <span class="text-xl text-center font-bold text-justify">
                    IndexedDB Data
                </span>
                <i class="pi pi-copy copy-icon" style="font-size: 1.25rem"
                    (click)="copyToClipboard(indexedDBData, 'indexedDB')"></i>
                <span *ngIf="indexedDBCopiedMessage" class="copied-message">{{ indexedDBCopiedMessage }}</span>
                <pre>{{ indexedDBData |json }}</pre>
            </div>
        </ng-template>


    </p-dialog>


</div>