import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Main',
                items: [
                    {
                        label: 'Orders',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['pages/orders'],
                    },
                    {
                        label: 'Manage Content',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Salesman',
                                icon: 'pi pi-fw pi-user',
                                routerLink: ['pages/salesman'],
                            },
                            {
                                label: 'Distributors',
                                icon: 'pi pi-fw pi-users',
                                routerLink: ['pages/distributors'],
                            },
                            {
                                label: 'Shops',
                                icon: 'fa fa-regular fa-shop',
                                routerLink: ['pages/shops'],
                            },
                            {
                                label: 'Inventory',
                                icon: 'pi pi-fw pi-truck',
                                routerLink: ['pages/inventory'],
                            },
                            {
                                label: 'Category',
                                icon: 'pi pi-fw pi-tag',
                                routerLink: ['pages/category'],
                            },
                            {
                                label: 'Daily Report',
                                icon: 'pi pi-fw pi-bookmark',
                                routerLink: ['pages/reports'],
                            },
                        ],
                    },
                    {
                        label: 'Reports',
                        icon: 'pi pi-fw pi-chart-bar',
                        items: [
                            {
                                label: 'Check-In Logs',
                                icon: 'pi pi-fw pi-bookmark',
                                routerLink: ['pages/checkin'],
                            },
                            {
                                label: 'Counters Visited',
                                icon: 'pi pi-fw pi-ticket',
                                routerLink: ['pages/counters-visit'],
                            },
                        ],
                    },
                    {
                        label: 'Settings',
                        icon: 'pi pi-wrench',
                        routerLink: ['pages/settings'],
                    },
                ],
            },
        ];
    }
}
