import { Component } from '@angular/core';
import { Button } from 'primeng/button';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
    selector: 'app-notfound',
    // standalone: true,
    templateUrl: './comming-soon.component.html',
})
export class CommingSoonComponent {}
